import * as Sentry from '@sentry/browser';
if (window.St && window.St.sentry) {
    Sentry.init({
        dsn: window.St.sentry.dsn_public,
        ignoreErrors: [
            /Request failed with status code 405/i,
            /Network Error/i,
            /SecurityError/i,
            /_adplanCv/i,
            /isLogin/i,
            /gtag/i,
            /Cross-origin script load denied/i,
        ],
        ignoreUrls: [
            /ladsp\.com/i,
            /amoad\.com/i,
            /i-mobile\.co\.jp/i,
            /doubleclick\.net/i,
            /facebook\.com/i,
            /facebook\.net/i,
        ],
        allowUrls: [
            // eslint-disable-next-line no-useless-escape
            /^https?:\/\/([a-z0-9\-]+)(\.[a-z0-9\-]+)?\.(sp-bridal\.jp|sp-wedding\.jp|smtk\.jp|official-wedding\.jp|official-website\.jp)/,
            /^https?:\/\/stpearl(.*)\.weddingpark\.net/,
        ],
    });
}
